import React from 'react'

export default function PatientBehaviorCard({ image, altText, heading, content }) {
  return (
    <div className="patient-behavior-card">
      <div className="patient-behavior-card__content">
        <div className="image__container">
          <img src={image} alt={altText} />
        </div>
        <div className="text__container">
          {heading && <p className="text-heading">{heading}</p>}
          <p>{content}</p>
        </div>
      </div>
    </div>
  )
}
