import React from 'react'

export default function StatCard({ headerText, content, topContent }) {
  return (
    <div className="stat-card">
      <div className="stat-card__content">
        {topContent}
        {headerText}
        {content}
      </div>
    </div>
  )
}
