import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Hero'
import StatCard from '../components/StatCard'
import PatientBehaviorCard from '../components/PatientBehaviorCard'
import CallToActionLink from '../components/CallToActionLink'
import QuoteBox from '../components/QuoteBox'
import iconReminder from '../images/icon-reminder.svg'
import iconText from '../images/icon-text.svg'
import iconAlert from '../images/icon-alert.svg'

export default function adherenceProblemPage() {
  return (
    <main className="adherence-problem-page">
      <Hero image="calendar-clock" heading="Medication non-adherence: the elephant in the room">
        <p>
          “More health benefits worldwide would result from improving adherence to existing treatments than developing
          any new treatment.”
          <br />
          <br />
          <em>–WHO (World Health Organization), 2003</em>
        </p>
      </Hero>

      <div className="page-section">
        <div className="page-section-content stat-cards__container">
          <h2 className="stat-cards-header">The high cost of non-adherence</h2>
          <div className="stat-cards">
            <StatCard
              topContent={<span className="subtext">Up to</span>}
              headerText={<header>$290B</header>}
              content={
                <span className="subtext">
                  cost to US government <sup>1</sup>
                </span>
              }
            />
            <StatCard
              headerText={<header>33-69%</header>}
              content={
                <span className="subtext">
                  unnecessary hospital admissions <sup>2</sup>
                </span>
              }
            />
            <StatCard
              headerText={<header>2x higher</header>}
              content={
                <span className="subtext">
                  mortality rates <sup>3</sup>
                </span>
              }
            />
          </div>
          <div className="footnotes">
            <p>
              1. Cost in avoidable hospitalizations, emergency care and outpatient visits due to poor patient adherence
              <br />
              2. Result of non-adherence (in the US)
              <br />
              3. For patients with diabetes and heart disease who don’t adhere to medications as compared to those who
              do
            </p>
          </div>
        </div>
      </div>

      <div className="page-section--blue patient-persistence">
        <div className="page-section-content">
          <h2>Patient persistence consistently less than 50%</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            Across disease states, patients struggle with persistence on medication. This is even lower in minority
            populations. The odds of non-adherence to antihypertensive medications were 47% & 44% higher among Black &
            Hispanic patients, as compared to white patients.
            <br />
            <br />
            Lower medication adherence among Black and Brown communities is not limited to one or a few health
            conditions — these disparities have been demonstrated in communities of color across a wide array of
            diseases.
            <br />
            <br />
            <em>
              Source:{' '}
              <a
                href="https://phrma.org/en/Equity/Addressing-Disparities-in-Medication-Access-and-Adherence"
                target="_blank"
                rel="noopener noreferrer"
              >
                PhRMA: Addressing Disparities in Medication Access and Adherence, May 2022
              </a>
            </em>
          </p>
        </div>
        <div className="page-section-content">
          <div>
            <StaticImage
              src="../images/patient-persistence-chart.png"
              alt="Patient persistence"
              placeholder="none"
              layout="constrained"
            ></StaticImage>
          </div>
          <div className="footnotes">
            <p>
              1. Calculated by averaging the 12-month values for each drug within each therapeutic area.
              <br />
              2. Percentage of patients who are fully adherent (>80% overall adherence) after 12 months.
              <br />
              3. Chronic obstructive pulmonary disease
            </p>
            <p>
              <em>
                Source:{' '}
                <a
                  href="https://www.mckinsey.com/industries/life-sciences/our-insights/improving-patient-adherence-through-data-driven-insights"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  McKinsey & Company: Improving patient adherence through data-driven insights, December 2018
                </a>
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="page-section the-challenge">
        <div className="page-section-content">
          <h2>The challenge</h2>
          <h2 className="sub-heading">
            “Drugs don’t work in patients who don’t take them.” –C. Everett Koop, former Surgeon General
          </h2>
        </div>
        <div className="page-section-content">
          <p>
            Living with a serious disease can be overwhelming. Despite best efforts, patients struggle to take their
            medication as intended: doses get missed, refills get delayed, or treatment stops entirely. Unfortunately,
            it takes about ten weeks to establish a new habit and only one week to lose it. <sup>1,2</sup>
            <br />
            <br />
            Traditional approaches to helping patients start and stay on their medication don’t work because they add to
            the burden or act too late. Oftentimes, these approaches are driven by dated prescription refill data and
            arrive when the medication-taking behavior has been broken. Newer methods, such as digital apps, suffer from
            low uptake and have no insight into the patient’s life arriving too late or too often, resulting in alert
            fatigue and patient disengagement.
            <br />
            <br />
            1.{' '}
            <em>
              <a href="https://pubmed.ncbi.nlm.nih.gov/15898858/" target="_blank" rel="noopener noreferrer">
                Can the theory of planned behavior predict the maintenance of physical activity?
              </a>
            </em>
            <br />
            2.{' '}
            <em>
              <a
                href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ejsp.674"
                target="_blank"
                rel="noopener noreferrer"
              >
                How are habits formed: Modelling habit formation in the real world
              </a>
            </em>
          </p>
        </div>
      </div>

      <div className="page-section the-solution">
        <div className="page-section-content">
          <h2>The solution</h2>
          <h2 className="sub-heading">Adherence = Retention + Compliance</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            Aidia is the in-home partner which <strong>effortlessly integrates</strong> into a patient's life. An
            elegantly simple and proven system designed to gently remind people to take their medications and intervene
            when they don’t — early enough to make a difference. Making sure not a day is lost on their path to better
            health.
          </p>
        </div>
        <div className="page-section-content patient-behavior-cards">
          <PatientBehaviorCard
            image={iconReminder}
            heading="Real-time reinforcement"
            content="Aidia makes medication-taking behavior visible to the patient, helping form a positive reward cycle."
          />
          <PatientBehaviorCard
            image={iconText}
            heading="Personalized messaging"
            content="Aidia provides personalized messaging via SMS & voice, using data to identify key touch-points across the patient journey."
          />
          <PatientBehaviorCard
            image={iconAlert}
            heading="Targeted Intervention"
            content="Real-time monitoring of patient behavior helps drive targeted intervention and coordination with care teams before it’s too late."
          />
        </div>
        <div className="page-section-content">
          <CallToActionLink color="blue" linkText="See the evidence" linkUrl="/evidence" />
        </div>
      </div>

      <div className="page-section--blue the-results">
        <div className="page-section-content stat-cards__container">
          <h2 className="stat-cards-header">The results</h2>
          <div className="stat-cards">
            <StatCard
              headerText={<header>50% +</header>}
              content={
                <div className="subtext">
                  avg increase in patient
                  <br />
                  retention on medicine
                  <br />
                  at 1 year
                </div>
              }
            />
            <StatCard
              headerText={<header>+3</header>}
              content={
                <div className="subtext">
                  additional
                  <br />
                  prescriptions at 1 year
                </div>
              }
            />
            <StatCard
              headerText={<header>10:1 RoI</header>}
              content={
                <>
                  <div className="subtext">
                    est. for pharma
                    <br />
                    manufacturers after
                    <br />1 year
                  </div>
                  <div className="subsubtext">(based on high priced specialty medications)</div>
                </>
              }
            />
          </div>
          <div className="stats-footnote">Data sources available upon request.</div>
        </div>
        <div className="page-section-content">
          <QuoteBox
            text="I would label this as priceless. There is nothing else that comes close. When you talk about life saving drugs, there should be no guessing if you took the drug, or not. This takes the guessing out of it."
            shadow={false}
          />
        </div>
      </div>
    </main>
  )
}
